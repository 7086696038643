<template>
  <page-section
      :title="title"
      :full-width="fullWidth"
  >
    <v-row v-if="!SM" class="mt-6">
      <v-col cols="4" v-for="(plan , i) in paymentPlans" :key="i">
        <payment-plan-card
            :plan="plan"
            :index="i"
            @select="$emit('select' , $event)"
            :register="register"
        />
      </v-col>
    </v-row>
    <ws-slider
        v-else
        :items="paymentPlans"
        :height="!SM ? 480 : null"
        width="100%"
        full-width
        left-arrow-style="ml-n4"
        right-arrow-style="mr-n4"
    >
      <template #item="{content}">
        <payment-plan-card
            :plan="content"
            @select="$emit('select' , $event)"
            :register="register"
        />
      </template>

    </ws-slider>

    <div v-if="!register">
      <div class="d-flex justify-center py-8">
        <ws-button
            :to="localeLink('pricing')"
            label="homepage.pricing.compare"
        />
      </div>

      <v-sheet
          class="justify-space-between align-center wsRoundedHalf pa-4" style="border : 2px solid #D7E5F0; background-color: transparent"
               :class="[{'d-flex' : !SM}]"
      >
        <div class="d-flex align-center">
          <v-img
              src="@/assets/illustrations/vaulanteer.png"
              class="mr-4"
              contain
              height="62px" max-width="62px" width="62px"
          />

          <h3 :style="`color : ${wsACCENT}`">
            {{ $t('homepage.pricing.social.title') }}
          </h3>
        </div>


        <v-btn
            @click="$store.state.homepage.displayContactForm = true"
            outlined :color="wsACCENT" class="noCaps ml-3"  :class="[{'mt-3' : SM}]" >
          {{ $t('homepage.pricing.social.apply') }}
        </v-btn>

      </v-sheet>

    </div>


  </page-section>
</template>

<script>
import paymentPlanCard from "@/components/pages/homepage_westudy/home/sections/UI/paymentPlanCard";
export default {
  name: "homeSectionPaymenPlans",
  components : {
    paymentPlanCard
  },
  props : {
    title : {
      type : String,
      default : 'PaymentPlans'
    },
    register : {
      type : Boolean ,
      default : false
    }
  },
  data() {
    return {
      text : '',
      fullWidth : false,
      paymentPlans : [
        {
          value: "basic",
          plan_alias : "westudy_base",
          price: "11",
          courses: 11,
          students: 200,
          storage : 25,
          managers : null,
          icon : 'mdi-account-school-outline'
        },
        {
          value: "elite",
          plan_alias : "westudy_elite",
          price: "37",
          courses: "∞",
          students: 1000,
          storage : 150,
          managers : 25,
          icon : 'mdi-briefcase-check-outline'
        },
        {
          value: "elite_plus",
          plan_alias : "westudy_elite_plus",
          price: "79",
          courses: "∞",
          students: 5000,
          storage : 350,
          managers : 50,
          branding : true,
          icon : 'mdi-crown-outline'
        }
      ]
    }
  },
  beforeMount() {
  }

}
</script>

<style scoped>

</style>