<template>
  <v-sheet color="white" :width="SM ? 300 : 350" class="py-6" >

    <!-- Heading-->
    <h2 style="font-size: 24px" class="mb-8"> {{ $t('homepage.register.business.title') }}</h2>
    <!-- New Business Input-->
    <div>
      <ws-text-field
          v-model="entityData.name"
          :label="$t('homepage.register.business.name.title')"
          :placeholder="$t('homepage.register.business.name.placeholder')"
          :tooltip="$t('homepage.register.business.name.tooltip')"
          :hide-details="false"
          :error="!!nameError"
          :error-messages="nameError"
          @input="nameError = null"
      />
      <ws-text-field
          v-model="entityData.alias"
          :label="$t('homepage.register.business.alias.title')"
          :placeholder="$t('school_name')"
          :tooltip="$t('homepage.register.business.alias.tooltip')"
          :hide-details="false"
          :error="!!aliasError"
          :error-messages="aliasError"
          @input="aliasError = null"
      >
        <template #prepend-inner>
          <span class="font-weight-medium" style="padding-top:5px">westudy.ua/</span>
        </template>
      </ws-text-field>

      <ws-select
          :label="$t('homepage.register.business.type.title')"
          :placeholder="$t('homepage.register.business.type.placeholder')"
          :tooltip="$t('homepage.register.business.type.tooltip')"
          v-model="entityData.business_type"
          :items="businessTypesSelect"
          :hide-details="false"
          :error="!!typeError"
          :error-messages="typeError"
          @input="typeError = null"
      />

      <ws-text-field
          v-model="entityData.description"
          :label="$t('homepage.register.business.description.title')"
          :placeholder="$t('homepage.register.business.description.placeholder')"
          :tooltip="$t('homepage.register.business.description.tooltip')"
          :hide-details="false"
          :error="!!nameError"
          :error-messages="nameError"
          maxlength="200"
          @input="nameError = null;"
          area
      />
      <h5 :style="`color : ${wsDARKLIGHT}`" class="mt-2 text-right mb-5">{{ entityData.description ? entityData.description.length : 0   }}/200</h5>

      <wsCheckBox
          v-model="termsConfirmed"
          @input="termsError = false"
          :error="termsError"
          class="mt-6 ml-n2"
          :small="false"
      >
        <h5 class="font-weight-light">
          {{  $t('homepage.register.terms.agree_with') }}
          <a :href="localeLink('about/terms',true)"
             :style="`color : ${termsError ? wsWARNING : wsATTENTION}`"
             class="linkHover noUnderline"
             target="_blank"
          >
            {{  $t('homepage.register.terms.with_rules') }}
          </a>
          {{  $t('homepage.register.terms.westudy_usage') }}
        </h5>
      </wsCheckBox>

    </div>

    <!-- Buttons -->
    <div class="d-flex justify-end align-center mt-10">
      <ws-button @click="nextAction" :loading="LOADING" :disabled="LOADING" >
        {{ $t('Continue') }}
        <v-icon>mdi-chevron-right</v-icon>
      </ws-button>
    </div>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "registerBusinessGeneral",
  data() {
    return {
      entityData : {},
      termsConfirmed : false,
      termsError : false,
      nameError : null,
      aliasError : null,
      typeError : null
    }
  },
  computed : {
    businessTypesSelect() {
      return [
        { text : this.$t("PrivateSchool"      ),       value : 'private_school',    },
        { text : this.$t("BusinessTrainings"  ),       value : 'business_trainings',},
        { text : this.$t("PsychologyCourses"  ),       value : 'psychology_courses',},
        { text : this.$t("ImprovementCourses" ),       value : 'upgrade',           },
        { text : this.$t("ChildCourses"       ),       value : 'children',          },
        { text : this.$t("FitnessSportTeacher"),       value : 'fitnes',            },
        { text : this.$t("ArtCourses"         ),       value : 'art',               },
        { text : this.$t("SchoolTeacher"      ),       value : 'school_teacher',    },
      ]
    }
  },
  methods : {
    ...mapActions('business',  [ 'CHECK_ALIAS' ]) ,

    async nextAction() {
      if ( !this.termsConfirmed ) {
        this.termsError = true
        return this.notify(this.$t('PleaseConfirmRulesAndConditions') , 'warning')
      }
      if ( !this.entityData.name ) {
        this.nameError = this.$t('EnterName')
        return
      }
      if ( !this.entityData.alias ) {
        this.aliasError = this.$t('PleaseEnterBusinessAlias')
        return
      }

      if ( !this.entityData.alias.match("^[a-zA-Z0-9_-]*$")) {
        this.aliasError = this.$t('AliasRegexError')
        return this.notify(this.$t('AliasRegexError'))
      }

      if ( this.entityData.alias.length < 3) {
        this.aliasError = this.$t('AliasLengthError')
        return this.notify(this.$t('AliasLengthError'))
      }

      if ( !await this.CHECK_ALIAS(this.entityData.alias) ) {
        this.aliasError = this.$t('BusinessAliasAlreadyExist')
        return this.notify(this.$t('BusinessAliasAlreadyExist'))
      }

      if ( !this.entityData.business_type ) {
        this.typeError = this.$t('EnterType')
        return
      }

      this.$emit('next' , this.entityData)
    }
  }
}
</script>

<style scoped>

</style>